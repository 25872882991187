<template>
    <!--   Reward Popup (star+ribbon)  -->
    <div class="c-reward_popup_body unselectable">
        <div class="c-reward_popup_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">
            <div class="c-reward_popup_2"
                 :style="{'width': realWidth+'px','height': realHeight+'px',}"
            >
                <!--   star+ribbon  -->
                <div class="c-reward_popup_2" style="position: absolute;"
                     :style="{'width': 840*ss*xMult*scale+'px','height': 500*ss*xMult*scale+'px','opacity':alpha,'top':imageTop*ss*xMult+'px',
                        'border-radius': 50*ss*xMult*scale+'px',}"
                >
                    <div class="c-reward_popup_2" style="width: 100%; height: 100%;">
                        <!--   star  -->
                        <img :src="imgStar" alt="" style="position: absolute;"
                             :style="{'height': 500*ss*xMult*scale+'px','top':0}"
                        />
                        <!--   ribbon  -->
                        <img :src="imgRibbon" alt="" style="position: absolute;"
                             :style="{'width': 830*ss*xMult*scale+'px','top':250*ss*xMult*scale+'px',}"
                        />
                        <div class="c-reward_popup_2" style="position: absolute;width: 100%;"
                             :style="{'top':275*ss*xMult*scale+'px','height':110*ss*xMult*scale+'px',}">
                            <div class="c-reward_popup_text" style="width: auto; color: #FFFFFF;"
                                 :style="{'font-size':40*ss*xMult*scale+'px','line-height':50*ss*xMult*scale+'px',}">
                                {{messages.m030_15_5}}
                            </div>
                            <div class="c-reward_popup_text" style="width: auto; color: #FFE500;"
                                 :style="{'font-size':50*ss*xMult*scale+'px','line-height':60*ss*xMult*scale+'px',}">
                                {{prizeText}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';

    import imgStar from "@/assets/images/cup.webp";
    import imgRibbon from "@/assets/svg/ribbon.svg";
    import audioLevel from "@/assets/audio/chips_claim.mp3";

    export default defineComponent({
        name: 'RewardPopup',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","popupLevelPopup"]),
            userLevel(){
                return this.$store.state.userLevel;
            }
        },
        components: {},
        data(){
            return {
                imgStar:imgStar,imgRibbon:imgRibbon, audioLevelUrl:audioLevel,
                scale:1,
                alpha:0,
                animStart:false,
                imageTop:0,
                audioLevel:null,
                prizeText:'',prizeKey:null,
            };
        },
        watch:{

        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
            this.audioLevel = new Audio(this.audioLevelUrl);
        },
        mounted() {
            if (this.animStart) return;
            const ts=this;
            this.animStart=true;
            const state=this.$store.state;
            try{
                if (this.audioLevel && state.sound) this.audioLevel.play();
                this.alpha=0;
                this.scale=1;
                this.imageTop=500;
                this.getPrizeText();
                //TweenMax.to(this,{alpha:1,duration:1,delay:0.2,ease: "power2.out",});
                TweenMax.to(this,{alpha:1,duration:0.5,delay:0.2,ease: "none",onComplete: ()=>{
                        TweenMax.to(this,{imageTop:0,alpha:0,duration:0.5,delay:1.5,ease: "none",onComplete: ()=>{
                                state.popupRewardPopup.visible=false;
                                ts.animStart=false;
                                ts.alpha=0;
                                if (state.popupRewardPopup.rewardsCompleted && state.popupRewardPopup.rewardsCompleted[ts.prizeKey])
                                    delete state.popupRewardPopup.rewardsCompleted[ts.prizeKey]
                                ts.prizeKey=null;
                                if (state.popupRewardPopup.rewardsCompleted && Object.keys(state.popupRewardPopup.rewardsCompleted).length>0){
                                    setTimeout(()=>{
                                        state.popupRewardPopup.visible=true;            //Show new reward again if any rewards completed
                                    },5000)
                                }
                            }
                        });//*/
                    }
                });
            }
            catch (e) {
                state.popupRewardPopup.visible=false;
                this.animStart=false;
                this.alpha=0;
                this.imageTop=500;
            }
        },
        methods: {
            getPrizeText(){
                const state=this.$store.state;
                const messages=state.messages;
                let val=0,prize='CHIPS';
                this.prizeKey=null;
                try{
                    const rewardsCompleted=state.popupRewardPopup.rewardsCompleted;
                    if (rewardsCompleted){
                        const keys=Object.keys(rewardsCompleted)
                        if (keys.length>0){
                            const reward=rewardsCompleted[keys[0]];
                            this.prizeKey=keys[0];
                            val=reward.prize_value?reward.prize_value:0;
                            prize=messages.reward_prize_type[reward.prize_type]?messages.reward_prize_type[reward.prize_type]:''
                            let ii=1;
                        }
                    }
                }catch (e) {
                    console.log('getPrizeText catch')
                    state.popupRewardPopup.rewardsCompleted={};
                }
                const s=`+${val} ${prize}`;
                this.prizeText=s;
            },
        },
    });
</script>

<style lang="css" scoped>

    .c-reward_popup_body{
        /*Body style for components in Zero level on device screen*/
        pointer-events: none;
        display: flex;
        overflow: hidden;
        position: absolute;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding:0;
        margin:0;
        width: 100vw;
        height: 100vh;
        transform: translate(0%, 0%);
        top: 0;
        left: 0;
        z-index: 11;
    }
    .c-reward_popup_abs{
        /*Second style into body for components in Zero level on device screen*/
        display: flex;
        overflow: hidden;
        position: absolute;
        transform: translate(0%, 0%);
        padding:0;
        margin:0;

        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .c-reward_popup_2{
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        /*background: rgba(0, 0, 0, 0.3);/**/
    }

    .c-reward_popup_text{
        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 600;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.03em;
    }


</style>
