<template>
    <div class="c-header-shop unselectable">
        <!--  Chips balance + Shop text + shop types buttons  -->
        <div class="c-shop_popup_rbc" style="height: auto; width: 100%; gap: 10px;">
            <!-- Chips balance -->
            <div class="c-shop_popup_balance">
                <div class="c-header-buy-balance">
                    {{ $filters.formatNumber( $store.getters.getChipsBalance ) }}
                </div>
                <img :src="require('../../assets/svg-icons/icon_chip2.svg')" alt="" style="height: 95%;"/>
            </div>

            <!-- Shop text + shop types buttons -->
            <div class="c-shop_popup_rbc" style="height: auto; width: auto; gap: 10px;">
                <div class="c-header-prizes-title">
                    Shop:
                </div>
                <div class="c-shop_popup_btns"
                     style="background: rgba(3, 18, 35, 0.15);width: auto; min-width: 236px;"
                >
                    <div class="c-header-prizes-header-buttons">
                        <button
                                class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCategory === 'chips' }"
                                @click="clickShopCategory('chips')"
                        >
                            Chips
                        </button>
                        <button
                                class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCategory === 'boost' }"
                                @click="clickShopCategory('boost')"
                        >
                            Boost
                        </button>
                        <button
                                class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCategory === 'vip' }"
                                @click="clickShopCategory('vip')"
                        >
                            VIP
                        </button>
                    </div>
                </div>
            </div>
            <div class="c-shop_popup_rcc" style="height: 100%; width: 202px; "/>
        </div>
        <!--  Connect or change wallet  -->
        <div class="c-shop_popup_rsc" style="height: 60px; width: 100%; gap: 30px; margin-top: 10px;">
            <!-- Connect wallet / Change wallet button -->
            <div class="c-header-buy-head-pair" style="gap: 10px">
                <button class="button-basic-orange" style="min-width: 150px;justify-content: center;align-items: center;"
                        @click="toggleWalletPopup">
                    {{ walletConnected?$t("general.change_wallet"):$t("general.connect_wallet2") }}
                </button>
            </div>

            <!-- Wallet icon -->
            <!-- Wallet name, wallet balance  -->
            <div class="c-header-buy-head-pair" style="gap: 10px;">
                <div v-if="walletConnected" style="display: contents">
                    <img v-if="getWalletLogo" :src="getWalletLogo"
                         style="width: 40px; height: 40px;"
                         alt=""/>
                    <span style="width: 100px;
                      text-overflow: ellipsis;
                      overflow: hidden;"
                    >
                        {{ getWalletUsername }}
                    </span>
                    <div class="c-header-buy-chips-balance">
                        {{ getWalletBalance }}
                    </div>
                </div>
            </div>
        </div>

        <!-- CHIPS/BOOST/VIP-->
        <div class="c-shop_popup_prizes" style="padding: 0 10%;margin-top: 10px;">
            <div v-if="shopCategory==='chips'" class="c-header-prizes-body-prize-cards" style="width: unset;justify-content: center;">
                <div style="display: contents;"
                     v-for="(item, index) in chipsPrice"
                     :key="'chipsBuy'+index"
                >
                    <div :ref="'chipsBuyCard'+index+1"
                         :class="isChipsDiscount(index)?'c-header-buy-card c-header-buy-card-discount':'c-header-buy-card'"
                         style="width: unset; margin-top:80px"
                    >
                        <img v-if="getChipsPriceChain[index]!==getChipsPriceChainWithDiscount[index]"
                             class="c-header-buy-card-discount-image"
                             :src="require('../../assets/images/'+(isMobile?'mobile/img_card_rays.webp':'img_card_rays.webp'))"
                             alt=""
                        />
                        <img :src="require('../../assets/images/'+(isMobile?`mobile/chips_${index+1}.webp`:`chips_${index+1}.webp`))"
                             alt=""
                             style="
                                position: absolute;
                                width: 70%;
                                left: 50%;
                                transform: translate(-50%,-50%);
                                  top: 0;
                                z-index: 1;
                             "
                        />
                        <div class="c-header-buy-card-glow c-header-buy-chips-card-glow"></div>
                        {{ $store.getters.getChipsAmount[index] }} Chips
                        <div class="c-header-buy-card-selector">
                            <div class="c-header-buy-card-selector-btn" @click="decrementDealBuy(index)">
                                -
                            </div>
                            {{ chipsBuyAmountDeal[index] }}
                            <div class="c-header-buy-card-selector-btn" @click="incrementDealBuy(index)">
                                +
                            </div>
                        </div>
                        <div class="c-shop_popup_buy_btn"
                             :style="getWalletUsername?'':'margin-top: unset; width: unset; padding: 0 16px;'"
                             @click="buyChips(index)"
                        >
                            <span v-if="getChipsPriceChain[index]!==getChipsPriceChainWithDiscount[index]" style="text-decoration-line: line-through; font-family: 'Rubik', sans-serif;">
                                {{ shopCurrency!=="usd"?(getChipsPriceChain[index]+" "+getChain):"$"+(getChipsPrice[index]) }}
                            </span>
                            <span style="font-family: 'Rubik', sans-serif;font-weight: bold;">
                                {{ shopCurrency!=="usd"?(getChipsPriceChainWithDiscount[index]):"$"+getChipsPriceWithDiscount[index] }} {{ shopCurrency!=="usd"?getChain:"" }}
                            </span>
                        </div>
                        <div v-if="getChipsPriceChain[index]!==getChipsPriceChainWithDiscount[index]" class="c-header-buy-card-discount-slider">
                            <div style="position: relative; height: 30px; width: 141px;">
                                <img :src="imgSlider" alt="" style="width: 141px; height: 29px;position: absolute; left: 0; top:0;"/>
                                <div class="c-header-buy-card-discount-slider-text">
                                    SALE -{{ getUserMonthlyCard?Math.max(getUserMonthlyCard.chipsDiscountPercentage,getChipsDiscount[index]):getChipsDiscount[index] }}%
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="shopCategory==='boost'" class="c-header-prizes-body-prize-cards" style="width: unset;justify-content: center; row-gap: 28px;">
                <div
                        v-for="(boostItem, index) in boost"
                        :key="shopCategory+index"
                        class="c-header-buy-card"
                        :class="{'c-header-buy-card-discount':getBoostDiscount[index] > 0}"
                        :style="boostInfoOnHover===index?
                          'width: 160px; margin-top:50px; z-index:2;':
                          'width: 160px; margin-top:50px;'"
                >
                    <img v-if="boostItem.img"
                         :src="require(`../../assets/images/${boostItem.img}`)"
                         style="
                       position: absolute;
                       width: 70%;
                       left: 50%;
                       transform: translate(-50%,-50%);
                       top: 0;
                       z-index: 1;
                     "
                         alt=""
                    />
                    <div v-if="boostItem.name" style="margin-top: 55px">
                        <div>{{ boostItem.name }}</div>
                        <br v-if="/xpRecovery/i.test(index)" />
                        <div v-if="/xpRecovery/i.test(index)">
                            {{ `Recover ${$store.getters.getRecoveryLevel - $store.getters.getLevel} Levels` }}
                        </div>
                    </div>
                    <img v-if="getBoostDiscount[index] > 0"
                         class="c-header-buy-card-discount-image"
                         :src="require('../../assets/images/'+(isMobile?'mobile/img_card_rays.webp':'img_card_rays.webp'))"
                         alt=""
                    />

                    <div v-if="!/xpFreeze|xpRecovery|unlockStats/i.test(index)"
                         class="c-header-buy-card-selector"
                    >
                        <div class="c-header-buy-card-selector-btn" @click="boostItem.amount>1?boostItem.amount--:null">
                            -
                        </div>
                        {{ boostItem.amount }}
                        <div class="c-header-buy-card-selector-btn" @click="boostItem.amount++">
                            +
                        </div>
                    </div>

                    <div v-if="shopCurrency==='usd'"
                         class="c-shop_popup_buy_btn"
                         :style="getWalletUsername?'':'margin-top: unset; width: unset; padding: 0 16px; min-width:90px;'"
                         @click="buyBoost(index)"
                    >
                        <span v-if="getBoostDiscount[index] === 0" style="font-family: 'Rubik', sans-serif;font-weight: bold;">
                            ${{ ($store.getters.getBoostPrice[index]) }}
                        </span>
                        <span v-if="getBoostDiscount[index] > 0" style="text-decoration-line: line-through; font-family: 'Rubik', sans-serif;">
                            ${{ ($store.getters.getBoostPrice[index]) }}
                        </span>
                        <span v-if="getBoostDiscount[index] > 0" style="font-family: 'Rubik', sans-serif;font-weight: bold;">
                            ${{ ($store.getters.getBoostPriceWithDiscount[index]) }}
                        </span>
                    </div>
                    <div v-else
                         class="c-shop_popup_buy_btn"
                         :style="getWalletUsername?'':'margin-top: unset;'"
                         @click="buyBoost(index)"
                    >
                        <span v-if="getBoostDiscount[index] === 0" style="font-family: 'Rubik', sans-serif; font-weight: bold">
                            {{ ($store.getters.getBoostPriceChain[index]) }} {{ getChain }}
                        </span>
                        <span v-if="getBoostDiscount[index] > 0" style="text-decoration-line: line-through; font-family: 'Rubik', sans-serif;">
                            {{ ($store.getters.getBoostPriceChain[index]) }} {{ getChain }}
                        </span>
                        <span v-if="getBoostDiscount[index] > 0" style="font-family: 'Rubik', sans-serif;font-weight: bold;">
                            {{ ($store.getters.getBoostPriceChainWithDiscount[index]) }} {{ getChain }}
                        </span>
                    </div>

                    <div class="c-header-prizes-body-prize-card-name c-header-prizes-body-prize-card-count shop-info-button"
                         style="background-color:unset;"
                         @mouseover="boostInfoOnHover=index"
                    >
                        <img :src="require('../../assets/images/'+(isMobile?'mobile/icon-info.webp':'icon-info.webp'))"
                             alt=""
                             :style=" getBoostDiscount[index] > 0?'width: 35px; filter: invert(1);':'width: 35px;'"
                        />
                        <div class="shop-info-bubble">
                            {{
                            boostItem.description+
                            "\n\nPrice: "+(shopCurrency==='gems'?$store.getters.getBoostPriceGemsWithDiscount[index]:
                            shopCurrency==='usd'?$store.getters.getBoostPriceWithDiscount[index]:
                            $store.getters.getBoostPriceChainWithDiscount[index])+" "+shopCurrency.toUpperCase()
                            }}
                        </div>
                    </div>
                    <div  v-if="/unlockStats/i.test(index)"
                          class="c-header-prizes-body-prize-card-name c-header-prizes-body-prize-card-count shop-info-button"
                          style="background-color: #ffffff1f; left: 0; right:unset; color:#ffffff75; display: flex; align-items: center; justify-content: center; font-size: 32px;"
                          @click="index==='unlockStats'?achievementsPreviewPopup=true:null"
                    >+
                    </div>


                    <div v-if="getBoostDiscount[index] > 0" class="c-header-buy-card-discount-slider">
                        <div style="position: relative; height: 30px; width: 141px;">
                            <img :src="imgSlider" alt="" style="width: 141px; height: 29px;position: absolute; left: 0; top:0;"/>
                            <div class="c-header-buy-card-discount-slider-text">
                                SALE -{{ $store.getters.getBoostDiscount[index] }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="shopCategory==='vip'" class="c-header-prizes-body-prize-cards" style="width: 100%; justify-content: center;">
                <div
                        v-for="(membership, index) in memberships"
                        :key="shopCategory+index"
                        class="c-header-buy-card "
                        :class="{'c-header-buy-card-discount':getMonthlyCardPriceWithDiscount[index]!== getMonthlyCardPrice[index]}"
                        style="height: unset; width: 165px; margin-bottom: 30px;"
                >
                    <img v-if="getMonthlyCardPriceWithDiscount[index]!== getMonthlyCardPrice[index]"
                         class="c-header-buy-card-discount-image"
                         :src="require('../../assets/images/'+(isMobile?'mobile/img_card_rays.webp':'img_card_rays.webp'))"
                         alt=""
                    />
                    <img v-if="membership.img"
                         :src="require(`../../assets/images/${membership.img}`)"
                         class="c-header-prizes-body-prize-card-img"
                         style="margin: 30px 0 0;"
                         alt=""
                    />
                    <div v-if="membership.name">
                        {{ membership.name }}
                    </div>

                    <div v-if="shopCurrency==='usd'"
                         class="c-shop_popup_buy_btn"
                         style="margin-top: unset; width: unset; padding: 0 16px; z-index:1;"
                         @click="buyVip(index)"
                    >
                        <span v-if="getMonthlyCardDiscount[index]>0" style="text-decoration-line: line-through; font-family: 'Rubik', sans-serif;">
                            ${{ (getMonthlyCardPrice[index]) }}
                        </span>
                        <span style="font-family: 'Rubik', sans-serif;font-weight: bold;">
                            ${{ (getMonthlyCardPriceWithDiscount[index]) }}
                        </span>
                    </div>
                    <div v-else
                         class="c-shop_popup_buy_btn"
                         style="margin-top: unset; z-index:1;"
                         @click="buyVip(index)"
                    >
                        <span v-if="getMonthlyCardPriceWithDiscount[index]!== getMonthlyCardPrice[index]" style="text-decoration-line: line-through; font-family: 'Rubik', sans-serif;">
                            {{ (getMonthlyCardPriceChain[index]) }} {{ getChain }}
                        </span>
                        <span style="font-family: 'Rubik', sans-serif;font-weight: bold;">
                            {{ (getMonthlyCardPriceChainWithDiscount[index]) }} {{ getChain }}
                        </span>
                    </div>

                    <div class="c-header-prizes-body-prize-card-name c-header-prizes-body-prize-card-count shop-info-button"
                         style="background-color:unset; top:0;"
                         @click="clickVipguideType(index)"
                    >
                        <img :src="require('../../assets/images/'+(isMobile?'mobile/icon-info.webp':'icon-info.webp'))"
                             alt=""
                             :style="'width: 35px;'+ (getMonthlyCardPriceWithDiscount[index]!== getMonthlyCardPrice[index]?'filter: invert(1);':'')"
                        />
                    </div>

                    <div v-if="getMonthlyCardDiscount[index]>0" class="c-header-buy-card-discount-slider">
                        <div style="position: relative; height: 30px; width: 141px;">
                            <img :src="imgSlider" alt="" style="width: 141px; height: 29px;position: absolute; left: 0; top:0;"/>

                            <div class="c-header-buy-card-discount-slider-text">
                                SALE -{{ getMonthlyCardDiscount[index] }}%
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="c-header-prizes-footer" style="height: 65px; flex-direction: column;">
            <div class="c-header-shop-menu">
                <div class="c-header-prizes-header-buttons-wrap c-header-shop-buttons-wrap"
                     style="margin: 0; height: 33px; min-width: 420px;justify-content: center;padding: 0;"
                >
                    <div class="c-header-prizes-header-buttons">
                        <button
                                class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCurrency === 'wax' }"
                                @click="clickShopCurrency('wax')"
                        >
                            WAX
                        </button>
                        <button
                                class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCurrency === 'sol' }"
                                @click="clickShopCurrency('sol')"
                        >
                            Solana
                        </button>

                        <button class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCurrency === 'bnb' }"
                                @click="clickShopCurrency('bnb')"
                        >
                            BNB
                        </button>

                        <button class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCurrency === 'pol' }"
                                @click="clickShopCurrency('pol')"
                        >
                            Polygon
                        </button>

                        <button class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCurrency === 'ton' }"
                                @click="clickShopCurrency('ton')"
                        >
                            TON
                        </button>

                        <button class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCurrency === 'ron' }"
                                @click="clickShopCurrency('ron')"
                        >
                            RON
                        </button>

                        <button
                                class="c-header-prizes-header-button"
                                :class="{ 'c-header-prizes-header-button-selected': shopCurrency === 'usd' }"
                                @click="clickShopCurrency('usd')"
                        >
                            USD
                        </button>
                    </div>
                </div>

            </div>
            <span v-if="hasMembershipDiscount && shopCategory==='chips'"
                  style="
                      font-size: 18px;
                      padding: 8px;
                      color: white;
                      filter: drop-shadow(1px 1px 2px black);
                      font-weight: lighter;
                      font-family: 'Rubik', sans-serif;
                    "
            >{{ "Your membership discount is "+getUserMonthlyCard.chipsDiscountPercentage+"%. The higher Discount will be applied" }}</span>
            <!-- <p v-else> *Discounts don't cumulate, the highest one will be applied</p> -->
        </div>
        <div @click="closeShopPopup">
            <img :src="require('../../assets/svg-icons/icon_close.svg')"
                 class="c-header-profile-close"
                 alt=""/>
        </div>

        <!-- Decoration images -->
        <div style="display: contents;">
            <!--img :src="require('../../assets/images/'+(isMobile?'mobile/gift_02.webp':'gift_02.webp'))"
                 style="
                        z-index: 0;
                        top: 100px;
                        position: absolute;
                        left: -70px;
                  "
                 alt=""
            /-->
            <img :src="require('../../assets/images/'+(isMobile?'mobile/gift_03.webp':'gift_03.webp'))"
                 style="
                        z-index: 0;
                        bottom: 92px;
                        position: absolute;
                        left: 69%;
                  "
                 alt=""
            />
            <!--img :src="require('../../assets/images/'+(isMobile?'mobile/star_01.webp':'star_01.webp'))"
                 style="
                        z-index: 0;
                        top: 33px;
                        position: absolute;
                        left: 212px;
                  "
                 alt=""
            /-->
            <img :src="require('../../assets/images/'+(isMobile?'mobile/star_02.webp':'star_02.webp'))"
                 style="
                        z-index: 0;
                        top: 50%;
                        position: absolute;
                        right: 48px;
                  "
                 alt=""
            />
            <img :src="require('../../assets/images/'+(isMobile?'mobile/star_03.webp':'star_03.webp'))"
                 style="
                        z-index: 0;
                        bottom: 180px;
                        position: absolute;
                        left: -44px;
                  "
                 alt=""
            />
            <img :src="require('../../assets/images/'+(isMobile?'mobile/element_01.webp':'element_01.webp'))"
                 style="
                        z-index: 0;
                        position: absolute;
                        right: 30px;
                        top: 160px;
                  "
                 alt=""
            />
            <img :src="require('../../assets/images/'+(isMobile?'mobile/element_02.webp':'element_02.webp'))"
                 style="
                        z-index: 0;
                        position: absolute;
                        left: 124px;
                        top: 50%;
                  "
                 alt=""
            />

            <div v-if="shopCurrency==='bnb'"
                 class="c-shop_popup_balance" style="bottom: 10px;font-weight: bold;">
                {{$t("general.bnb_minimal")}}
            </div>
        </div>
    </div>
</template>

<script>
    import { defineComponent } from 'vue';
    import { mapGetters } from "vuex";
    import ButtonSvgOval from "../lucky-wheel/ButtonSvgOval";
    import imgSlider from "../../assets/svg-icons/slider.svg"

    export default defineComponent({              //Not working debugger in chrome
        name: "ShopPopup",
        components: {ButtonSvgOval,},
        computed: {
            ...mapGetters(["shopCategory","shopCurrency","memberships","boost","getBoostDiscount","chipsBuyAmountDeal","isMobile","hasMembershipDiscount","getChain",
                "getMonthlyCardDiscount","getMonthlyCardPrice","getMonthlyCardPriceWithDiscount","getMonthlyCardPriceChain","getMonthlyCardPriceChainWithDiscount",
                "vipguideType","vipguidePopup","isShopWalletSelected","getWalletLogo","chipsPrice","getChipsPriceChainWithDiscount","getChipsPriceChain","getChipsPrice",
                "getWalletUsername","getWalletBalance","getUserMonthlyCard","getChipsDiscount","walletConnected","getChipsPriceWithDiscount",
                "ss","realWidth","realHeight","xMult",
            ]),
            btnRad(){
                const rad=135*this.$store.state.ss*this.$store.getters.xMult;
                return rad;
            },

        },
        created() {
            MyLog("ShopPopup created")
        },
        data: function () {
            return {
                imgSlider:imgSlider,
                boostInfoOnHover: "",
                isChipsDiscount(index){
                    //getChipsPriceChain[index]!==getChipsPriceChainWithDiscount[index]}
                    const getters=this.$store.getters;
                    let dsc=getters.getChipsPriceChain[index]!==getters.getChipsPriceChainWithDiscount[index];
                    return dsc;
                },
            }
        },
        methods:{
            async buyChips(type) {
                const state=this.$store.state;
                const getters=this.$store.getters;
                this.productType="Chips"
                this.productPackage=type
                this.productQuantity=state.chipsBuyAmountDeal[type]
                if (getters.walletConnected) {
                    state.buyChipsType = type;
                    await this.$store.dispatch("getState");
                    //const newPrice = getters.totalChipsPriceChainWithDiscount[state.buyChipsType];
                    this.toggleBuyChipsConfirmationPopup();
                } else {
                    state.selectedChain=getters.getChain.toLowerCase()
                    this.toggleWalletPopup();
                }
            },
            async buyBoost(type) {
                const state=this.$store.state;
                const getters=this.$store.getters;
                this.productQuantity=state.boost[type].amount;
                this.productType="Boost";
                this.productPackage=type;
                if (getters.walletConnected) {
                    if(state.boost[type].amount<=0){
                        this.textErrorPopup="You need to select the amount of what you want to buy 1st";
                        this.errorPopup=true;
                        return
                    }
                    await this.$store.dispatch("getState");
                    state.selectedBoost = type;
                    state.buyBoostConfirmationPopup=true;

                } else {
                    state.selectedChain=getters.getChain.toLowerCase()
                    this.toggleWalletPopup();
                }
            },
            buyVip(type) {
                const state=this.$store.state;
                const getters=this.$store.getters;
                this.productType="MonthlyCard"
                this.productPackage=type
                if (getters.walletConnected){
                    if( getters.getUsername ){
                        state.selectedVip=type;
                        state.buyVipConfirmationPopup=true;
                    }else{
                        state.loginPopup = true;
                        this.$store.commit("setWalletPopupOpen", state.loginPopup);
                    }
                }else {
                    state.selectedChain=getters.getChain.toLowerCase()
                    this.toggleWalletPopup();
                }
            },
            clickShopCategory(shopCategory){
                const state=this.$store.state;
                state.shopCategory=shopCategory;
                this.getMemberships();
            },
            clickShopCurrency(currency){
                this.$store.dispatch("clickShopCurrency", currency);
            },

            closeShopPopup() {
                const state=this.$store.state;
                state.shopPopup = false;
                state.isShopWalletSelected = false;
                this.$store.commit("setShopPopupOpen", false);
            },

            clickVipguideType(index){
                const state=this.$store.state;
                state.vipguideType=index;
                state.vipguidePopup=true;
            },
            incrementDealBuy(i) {
                const state=this.$store.state;
                if (state.chipsBuyAmountDeal[i] < 99)
                    state.chipsBuyAmountDeal[i] = state.chipsBuyAmountDeal[i] + 1
            },
            decrementDealBuy(i) {
                const state=this.$store.state;
                if (state.chipsBuyAmountDeal[i] > 1)
                    state.chipsBuyAmountDeal[i] = state.chipsBuyAmountDeal[i] - 1
            },

            getActiveBoosts() {
                const state=this.$store.state;
                const getters=this.$store.getters;
                state.memberships.bronze.priceUsd = getters.getMonthlyCardPrice.bronze;
                state.memberships.silver.priceUsd = getters.getMonthlyCardPrice.silver;
                state.memberships.gold.priceUsd = getters.getMonthlyCardPrice.gold;
                let chain = getters.getChain;
                this.$store.commit("setChain","WAX")
                state.memberships.bronze.priceWax = getters.getMonthlyCardPriceChain.bronze;
                state.memberships.silver.priceWax = getters.getMonthlyCardPriceChain.silver;
                state.memberships.gold.priceWax = getters.getMonthlyCardPriceChain.gold;
                this.$store.commit("setChain","SOL")
                state.memberships.bronze.priceSolana = getters.getMonthlyCardPriceChain.bronze;
                state.memberships.silver.priceSolana = getters.getMonthlyCardPriceChain.silver;
                state.memberships.gold.priceSolana = getters.getMonthlyCardPriceChain.gold;
                this.$store.commit("setChain",chain)
            },
            getMemberships() {
                const state=this.$store.state;
                const getters=this.$store.getters;
                state.memberships.bronze.priceUsd = getters.getMonthlyCardPrice.bronze;
                state.memberships.silver.priceUsd = getters.getMonthlyCardPrice.silver;
                state.memberships.gold.priceUsd = getters.getMonthlyCardPrice.gold;
                let chain = getters.getChain;
                this.$store.commit("setChain","WAX")
                state.memberships.bronze.priceWax = getters.getMonthlyCardPriceChain.bronze;
                state.memberships.silver.priceWax = getters.getMonthlyCardPriceChain.silver;
                state.memberships.gold.priceWax = getters.getMonthlyCardPriceChain.gold;
                this.$store.commit("setChain","SOL")
                state.memberships.bronze.priceSolana = getters.getMonthlyCardPriceChain.bronze;
                state.memberships.silver.priceSolana = getters.getMonthlyCardPriceChain.silver;
                state.memberships.gold.priceSolana = getters.getMonthlyCardPriceChain.gold;
                this.$store.commit("setChain",chain)
            },
            toggleBuyChipsConfirmationPopup() {
                const state=this.$store.state;
                state.buyChipsConfirmationPopup = true;
            },
            toggleWalletPopup() {
                const state=this.$store.state;
                state.loginPopup = true;
                this.$store.commit("setWalletPopupOpen", state.loginPopup);
            },
        },
    }
    );

</script>

<style lang="scss" scoped>
    .c-shop_popup_rcc{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        overflow: visible;
    }
    .c-shop_popup_rsc{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        overflow: visible;
    }
    .c-shop_popup_rbc{
        display: flex;
        position: relative;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        overflow: visible;
    }
    .c-shop_popup_btns{
        background-color: rgba(255,255,255,0.15);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        z-index: 1;
    }

    .c-shop_popup_prizes{
        display: flex;
        height: 100%;
        width: 100%;
        overflow-y: auto;
        transition: $transition-medium;
        z-index: 1;

        @include custom-scrollbar;

        @include media-max(520px) {
            flex-direction: column;
            gap: 10px;
        }
    }

    .c-shop_popup_balance{
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;
        height: auto;

        font-family: 'Rubik', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        /* or 19px */

        letter-spacing: -0.03em;

        /* white */

        color: #FFFFFF;
    }

    .c-shop_popup_buy_btn {
        color: $white;
        /* btn_profile_1 */
        position:relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /* Auto layout */

        cursor: pointer;
        width: 151px;
        height: 41px;
        border-radius: 50px;
        border: none;
        font-stretch: normal;
        font-style: normal;

        font-family: 'Rubik', sans-serif;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: -0.03em;
        transition: 0.5s;

        margin: fill;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 58.54%), linear-gradient(95.19deg, #0CFF7C -16.14%, #006c54 137.96%) var(--x, 0)/200%;
        flex: none;
        flex-grow: 0;

        &:hover {
            --x: 100%;
            box-shadow: 0 5px 30px rgba(57, 231, 156, 0.5);
        }

        &:after {
            background: linear-gradient(102.76deg, #06A071 3.6%, #BCFFDE 53.89%, #06A071 99.19%);
            content: '';
            width: 100%;
            height: 100%;
            padding: 2px;
            -webkit-mask:
                    linear-gradient(#fff 0 0) content-box,
                    linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            border-radius: 50px;
            position: absolute;
        }
    }
</style>
