<template>
    <!--   Rewards popup    -->
    <div class="c-components_body unselectable" style="background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));z-index: 10;">
        <div class="c-components_abs"
             :style="{'top': 0*ss+'px','left':getLeft+'px','width': realWidth+'px','height': realHeight+'px',}">

            <div class="c-app_csc c-rewards_bg" style="overflow: visible;"
                 :style="{'width': widthR+'px','height': heightR+'px','gap': widthR*0.02+'px','border-radius': widthR*0.02+'px',}"
            >
                <div class="c-app_rcc" style="overflow: visible;"
                    :style="{'width': widthR+'px','height': widthR*0.05+'px','margin-top': widthR*0.02+'px',}"
                >
                    <!--  Quests text in title  -->
                    <div class="c-app_rcc"
                         :style="{'width': widthR*0.25+'px','height': widthR*0.05+'px','margin-top': 0*ss*xMult+'px',}"
                    >
                        <div class="c-rewards_text"
                             :style="{'width': 'auto','height': widthR*0.05+'px',
                                'font-size': widthR*0.04+'px','line-height': widthR*0.05+'px',}"
                        >
                            {{messages.m055_01}}
                        </div>
                    </div>

                    <!--  Reward type: tasks/daily/social   -->
                    <div class="c-rewards_head"
                         :style="{'width': widthR*0.5+'px','height': widthR*0.05+'px', 'gap': 10*ss*xMult+'px','margin-top': 0*ss*xMult+'px',}"
                    >
                        <div class="c-rewards_head_btns unselectable"
                             :style="{'width': widthR*0.45+'px','height': widthR*0.05+'px', 'border-radius': 60*ss*xMult+'px',}"
                        >
                            <div class="c-rewards_head_btn"
                                 :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': widthR*0.05+'px', 'border-radius': 60*ss*xMult+'px',
                                'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                                'background': rewardCurrent==='tasks'?btnBg:'none',
                                'box-shadow': rewardCurrent==='tasks'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                                 @click="onClickBtn('tasks')"
                            >
                            <span class="c-rewards_head_btn_txt"
                                  :style="{'width': 'auto','height': widthR*0.04+'px', 'font-size': widthR*0.025+'px','line-height': widthR*0.04+'px',}"
                            >
                                {{messages.m055_02}}
                            </span>
                            </div>
                            <div class="c-rewards_head_btn"
                                 :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': widthR*0.05+'px', 'border-radius': 60*ss*xMult+'px',
                                'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                                'background': rewardCurrent==='daily'?btnBg:'none',
                                'box-shadow': rewardCurrent==='daily'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                                 @click="onClickBtn('daily')"
                            >
                            <span class="c-rewards_head_btn_txt"
                                  :style="{'width': 'auto','height': widthR*0.04+'px', 'font-size': widthR*0.025+'px','line-height': widthR*0.04+'px',}"
                            >
                                {{messages.m055_03}}
                            </span>
                            </div>
                            <div class="c-rewards_head_btn"
                                 :style="{'gap': 10*ss*xMult+'px','width': 97*ss*xMult+'px','height': widthR*0.05+'px', 'border-radius': 60*ss*xMult+'px',
                                'padding': 0*ss*xMult+'px '+15*ss*xMult+'px',
                                'background': rewardCurrent==='social'?btnBg:'none',
                                'box-shadow': rewardCurrent==='social'?'0 0 20px rgba(253,134,81,.8)':'none'}"
                                 @click="onClickBtn('social')"
                            >
                            <span class="c-rewards_head_btn_txt"
                                  :style="{'width': 'auto','height': widthR*0.04+'px', 'font-size': widthR*0.025+'px','line-height': widthR*0.04+'px',}"
                            >
                                {{messages.m055_04}}
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <!--scroll static
                style="background: rgba(0,0,0,0.4)"
                -->
                <div class="c-app_ccc"
                     :style="{'width': widthR*0.95+'px','height':'auto','max-height': heightR-widthR*0.11+'px',}">
                    <!--scroll dinamic-->
                    <div class="c-app_scroll" style="scrollbar-width: thin;"
                         :style="{'width': widthR*0.95+'px','height':'auto','gap': widthR*0.015+'px',
                        'padding-top': 10*ss*xMult+'px','padding-bottom': 10*ss*xMult+'px',}">

                        <!-- blue line -->
                        <!--div class="c-app_ccs" style="border-bottom: 2px solid rgb(25, 201, 243);"
                             :style="{'width': (orient==='V'?1000:2030)*ss*xMult-(orient==='V'?16:32)+'px','height': '2px',}"
                        /-->

                        <!-- Rewards list -->
                        <RewardElem v-for="(rewardKey, rewardIndex) in rewardKeys"
                                    :key="rewardIndex"
                                    :img="rewardList[rewardKey].image"
                                    :msg="rewardList[rewardKey].description"
                                    :prize-type="rewardList[rewardKey].prize_type"
                                    :prize-image="rewardList[rewardKey].prize_image"
                                    :prize-active="true"
                                    :reward-status="parseInt(rewardList[rewardKey].reward_status)"
                                    :prize-val="rewardList[rewardKey].prize_value"
                                    :reward-url="rewardList[rewardKey].url"
                                    :reward-id="rewardList[rewardKey].id"
                                    :start-point-s="getStartPointS(rewardList[rewardKey].start_point)"
                                    :upload-id="'uploadForm'+rewardIndex"
                                    :file-id="'fileName'+rewardIndex"
                        />
                    </div>
                </div>

                <!--   X button   -->
                <div style="position: absolute"
                     :style="{'top': widthR*0.02+'px','right': widthR*0.02+'px', 'width': widthR*0.035+'px','height': widthR*0.035+'px',}"
                >
                    <ButtonSvg :svg-normal="btn_xSvg" :svg-clicked="btn_xSvg"
                               :bg-width="widthR*0.035"
                               :bg-height="widthR*0.035"
                               :callback="btn_xClick" :callback-wait="true"
                    />
                </div>
            </div>
        </div>
    </div>


</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import ButtonSvg from "../lucky-wheel/ButtonSvg";
    import ButtonSvgOval from "../lucky-wheel/ButtonSvgOval";
    import RewardElem from "./RewardElem";

    import btn_xSvg from "../../assets/svg-icons/lucky/btn_x.svg";
    import img_chips from "@/assets/svg/chips.svg";
    import img_chips_4  from "@/assets/images/digitalPrizes/chips_4.webp";

    export default defineComponent({
        name: 'Rewards',
        computed: {
            ...mapGetters(["ss", "realWidth", "realHeight","xMult","getLeft","messages","prizes","getClaimPrizes","popupQuickHelp",
                "achievementsStatsUnlocked","userData","rewards","rewardCurrent"]),
            widthR(){
                let w=776;
                const getters=this.$store.getters;
                w=Math.min(w,getters.realWidth*0.8);
                w=Math.max(w,getters.realWidth*0.5);
                return w;
            },
            heightR(){
                let w=this.widthR;
                const getters=this.$store.getters;
                w=Math.min(w,getters.realHeight*0.8);
                return w;
            },
            orient(){
                return 'H';
            },
            rewardKeys(){
                const state=this.$store.state;
                try {
                    const rewardC=state.rewards[state.rewardCurrent];
                    const keys = Object.keys(rewardC);
                    try {
                        keys.sort((a, b) => {
                            if (rewardC[a].numb !== rewardC[b].numb) {
                                return parseInt(rewardC[a].numb) - parseInt(rewardC[b].numb); // сортировка по первичному критерию numb
                            }
                            return parseInt(rewardC[a].id) - parseInt(rewardC[b].id); // сортировка по вторичному критерию id, если numb равны
                        });
                    }catch (e) {}
                    return keys;
                }
                catch (e) {
                    return []
                }
            },
            rewardList(){
                const state=this.$store.state;
                const lst=state.rewards[state.rewardCurrent];
                return lst;
            },
            btnBg() {
                return 'linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%)';
            },

        },
        components: {ButtonSvg,ButtonSvgOval,RewardElem,},
        data(){
            return {
                btn_xSvg:btn_xSvg,img_chips:img_chips,img_chips_4:img_chips_4,
                allGames:[],allGamesAcievKeys:[],otherAchievements:[],        //
            }
        },
        created() {

        },
        mounted() {
        },
        async beforeMount() {
            this.$store.dispatch('rewardsUpdate');
        },
        methods: {
            btn_xClick(){
                this.$store.state.popupVisible.rewards=false;
            },
            getStartPointS(startPoint){
                let val='';
                if (startPoint){
                    val=JSON.stringify(startPoint)
                }
                return val;
            },
            onClickBtn(btn) {       //tasks/daily/social
                const state = this.$store.state;
                if (state.rewardCurrent === btn)
                    return;

                state.rewardCurrent = btn;
                this.$store.dispatch('rewardsUpdate');
            },

            getDate1(achievKey){
                let txt='';
                try {
                    const userData = this.$store.getters.userData;
                    txt=Utils.getDateOrNow(userData.achievements[achievKey].timestamp)
                }
                catch (e) {}
                return txt;
            },
            getTitle(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    const userData = this.$store.getters.userData;
                    txt=achievObj[userData.achievements[achievKey].name].title;
                }
                catch (e) {}
                return txt;
            },
            getTitle2(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    txt=achievObj[achievKey].title;
                }
                catch (e) {}
                return txt;
            },
            getDescription(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    const userData = this.$store.getters.userData;
                    txt=achievObj[userData.achievements[achievKey].name].description;
                }
                catch (e) {}
                return txt;
            },
            getDescription2(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.getters.achievObj;
                    txt=achievObj[achievKey].description;
                }
                catch (e) {}
                return txt;
            },
            getOtherPrizeText(otherName){
                let txt='0 / 0';
                try {
                    let achievement={amount:0,total:0};
                    this.otherAchievements.forEach((obj)=>{
                        if (obj.name===otherName){
                            achievement=obj;
                        }
                    })
                    let amountTxt=achievement.amount===undefined || achievement.amount===null?'undefined':achievement.amount.toLocaleString();
                    txt=amountTxt+ ' / '+(achievement.total?achievement.total.toLocaleString():'0');
                }
                catch (e) {}
                return txt;
            },
            getOtherPrizePercent(otherName){
                let p=0;
                try {
                    let achievement={amount:0,total:0};
                    this.otherAchievements.forEach((obj)=>{
                        if (obj.name===otherName){
                            achievement=obj;
                        }
                    })
                    let amount=achievement.amount?achievement.amount:0;
                    let total=achievement.total?achievement.total:1;
                    p=Math.round(amount/total*100);
                }
                catch (e) {}
                return p;
            },
            getChips(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.state.blockspinState.achievementRewards;
                    const userData = this.$store.getters.userData;
                    txt=achievObj[userData.achievements[achievKey].name].chips;
                    txt=txt.toLocaleString();
                }
                catch (e) {}
                return txt;
            },
            getChips2(achievKey){
                let txt='';
                try {
                    const achievObj = this.$store.state.blockspinState.achievementRewards;
                    txt=achievObj[achievKey].chips;
                    txt=txt.toLocaleString();
                }
                catch (e) {}
                return txt;
            },
        },

    });
</script>

<style lang="css" scoped>
    /*::-webkit-scrollbar {
        width: 10px;
        background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 5px;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background: linear-gradient(91.95deg,#fcc031 -9.33%,#ff2e2f 92.67%);
    }

    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        border-radius: 10px;
        background-color: hsla(0,0%,100%,.1);
    }/**/

    .c-rewards_bg{
        background: radial-gradient(52.63% 87.8% at 50.03% 4.04%, #0D4455 0%, #030F20 100%), linear-gradient(94deg, #00C6FB 0.27%, #005BEA 105.74%);
        border: 2px solid #00b3bd;
    }

    .c-rewards_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 500;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;
    }

    .c-rewards_head_btns{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.4);
    }

    .c-rewards_head_btn_txt{
        font-family: 'Rubik';
        font-style: normal;
        font-weight: 500;
        text-align: center;
        letter-spacing: -0.03em;
        flex: none;
        order: 0;
        flex-grow: 0;
        color: white;
    }

    .c-rewards_head_btn{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        flex: none;
        order: 0;
        flex-grow: 1;
    }

    .c-rewards_head{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        /*background: rgba(0, 0, 0, 0.2);/**/
    }

</style>
