<template>
    <!--   Element for Rewards, props: img, msg, prizeVal,    -->
    <div class="c-app_ccs" style="background: hsla(0,0%,100%,.1);"
         :style="{'width': widthR*0.45+'px','height': widthR*0.12+'px','gap': 0*ss*xMult+'px','border-radius': widthR*0.015+'px',}"
    >

        <!-- Image, description   -->
        <div class="c-app_rsc"
             :style="{'width': 'auto','height': widthR*0.06+'px','margin-left': widthR*0.015+'px','gap': widthR*0.01+'px','opacity': rewardStatus===2?'1':'0.4',}">
            <img :src="img" alt=""
                 :style="{'width': widthR*0.05+'px','height': widthR*0.05+'px',}"/>

            <div class="c-app_ccs"
                 :style="{'width': (showLinkBtn?0.22:0.37)*widthR+'px','max-width': (showLinkBtn?0.22:0.37)*widthR+'px','height': widthR*0.06+'px',}">
                <!--  Reward description text -->
                <div class="c-reward_elem_text c-app_css" style="white-space: pre-wrap; text-align: -webkit-left;"
                     :style="{'width': 'auto','max-width': (showLinkBtn?0.22:0.37)*widthR+'px','max-height': widthR*0.05+'px',
                        'font-size': widthR*0.022+'px','line-height': widthR*0.025+'px',}">
                    {{msg}}
                </div>
            </div>

            <!--  upload image btn if need @click="clickUpload" -->
            <div v-if="showLinkBtn" class="c-app_ccc" style="color: #00a84c"
                 :style="{'width':  widthR*0.13+'px','height': widthR*0.045+'px','border-radius': widthR*0.15+'px',
                    'margin-right': 20*ss*xMult+'px','background':'linear-gradient(95deg, #00A84C -16.14%, #00AF90 137.96%)'}">
                <form :id="uploadId" enctype="multipart/form-data" class="c-app_ccc"
                      :style="{'width': widthR*0.13+'px','height': widthR*0.045+'px',}"
                >
                    <label :for="fileId" class="c-reward_elem_text c-app_ccc" style="white-space: pre-wrap;"
                           :style="{'width': 'auto','max-width': widthR*0.12+'px','height': widthR*0.04+'px',
                           'font-size': widthR*0.018+'px','line-height': widthR*0.02+'px',}"
                    >{{messages.m055_07}}</label>
                    <input class="c-reward_elem_text c-app_ccc" style="display: none;"
                           @change="updateFileName()"
                           type="file" name="image" accept="image/*" :id="fileId"
                    />
                </form>
            </div>

        </div>

        <!--  chips prize, prize progress status   -->
        <div class="c-app_rbc"
             :style="{'width': widthR*0.43+'px','height': widthR*0.05+'px','margin-left': widthR*0.015+'px','gap': widthR*0.01+'px',}">

            <!--  chips prize -->
            <div class="c-app_rbc" style="background: linear-gradient(94deg, rgb(0, 198, 251) 0.27%, rgb(0, 91, 234) 105.74%);"
                 :style="{'width': widthR*0.22+'px','height': widthR*0.04+'px','border-radius': widthR*0.02+'px',
                    'opacity': rewardStatus===2?'1':'0.4',}">
                <div class="c-reward_elem_text c-app_ccc"
                     :style="{'width': 'auto','height': widthR*0.04+'px','font-size': widthR*0.022+'px','line-height': widthR*0.04+'px','margin-left': widthR*0.015+'px',}">
                    {{messages.m050_03}}
                </div>
                <div class="c-app_rbc"
                     :style="{'width': 'auto','height': widthR*0.04+'px','margin-right': widthR*0.01+'px','gap': widthR*0.01+'px',}">
                    <div class="c-reward_elem_text c-app_ccc"
                         :style="{'width': 'auto','height': widthR*0.04+'px','font-size': widthR*0.022+'px','line-height': widthR*0.04+'px',}">
                        {{getPrizeVal}}
                    </div>
                    <img :src="img_chips" alt=""
                         :style="{'width': widthR*0.025+'px','height': widthR*0.025+'px',}"/>
                </div>
            </div>

            <!--  prize status progress/done/not avail -->
            <div class="c-app_ccc" @click="clickLink"
                 :style="{'width':  widthR*0.13+'px','height': widthR*0.045+'px','border-radius': widthR*0.15+'px',
                    'margin-right': widthR*0.01+'px','background':getBackground}">
                <div v-if="showLinkBtn" class="c-reward_elem_text c-app_ccc" style="white-space: pre-wrap;"
                     :style="{'width': 'auto','height': widthR*0.045+'px','font-size': widthR*0.018+'px','line-height': widthR*0.02+'px',}"
                >
                    {{screenshotName?messages.m055_06:messages.m055_05}}
                </div>
                <img v-else :src="getImage" alt=""
                     :style="{'width': widthR*0.035+'px','height': widthR*0.035+'px',}"/>
            </div>
        </div>

        <!--    click region -->
        <!--div v-if="!uploadRequired" style="width: 100%; height: 100%;position: absolute;left: 0;top:0;"
             @click="clickReward"
        /-->

    </div>

</template>

<script>
    import {mapGetters} from "vuex";
    import { defineComponent } from 'vue';
    import img_chips from "@/assets/svg/chips.svg";
    import {priceFraction} from "../../constants";
    import img_lock from "@/assets/svg/icon_lock.svg";
    import img_progress from "@/assets/svg/sand_watch.svg";
    import img_v from "@/assets/svg/icon_v.svg";
    import Backend from "@/classes/backend";

    export default defineComponent({
        name: 'RewardElem',
        components: {},
        computed: {
            ...mapGetters(["ss","xMult","messages"]),
            widthR(){
                let w=776;
                const getters=this.$store.getters;
                w=Math.min(w,getters.realWidth*0.8);
                w=Math.max(w,getters.realWidth*0.5);
                return w;
            },
            getPrizeVal(){
                let val=0;
                try{
                    const points=priceFraction[this.getPrizeType()]?priceFraction[this.getPrizeType()]:0;
                    val=Utils.isNumber(this.prizeVal)?parseFloat(this.prizeVal):0;
                    val=val.toFixed(points);
                }
                catch (e) {}
                return val;
            },
            getBackground(){
                // 1 - done/ 2 - progress/ 3 - not avail
                //border-radius: 50px;
                //let s='#717171';

                let bg='linear-gradient(95deg, #b6b6b6 -16.14%, #717171 137.96%)';
                bg=this.rewardStatus===1?'linear-gradient(95deg, #0CFF7C -16.14%, #00AF90 137.96%)':bg;
                //bg=this.rewardStatus===2?'linear-gradient(95deg, #FFC83C -9.98%, #DC8400 118.99%)':bg;
                bg=this.rewardStatus===2?'linear-gradient(91.95deg, rgb(252, 192, 49) -9.33%, rgb(255, 46, 47) 92.67%)':bg;

                return bg;
            },
            getImage(){
                // 1 - done/ 2 - progress/ 3 - not avail
                //border-radius: 50px;
                //let s='#717171';

                let bg=this.img_lock;
                bg=this.rewardStatus===1?this.img_v:bg;
                bg=this.rewardStatus===2?this.img_progress:bg;
                return bg;
            },
            showLinkBtn(){        //if upload image required and image_name empty
                const b=this.showLinkBtnThis();
                return b;
            },
            uploadRequired(){        //if upload image required
                let b=false;
                try{
                    if (this.rewardUrl){
                        const obj=JSON.parse(this.rewardUrl);
                        if (obj.upload_req===true){
                            b=true;
                        }
                    }
                }catch (e) {}
                return b;
            },
        },
        data(){
            return {
                img_chips:img_chips,img_progress:img_progress,
                img_lock:img_lock,img_v:img_v,
                screenshotName:'',startUpload:false,
            };
        },
        created() {
            //console.log('svgHeight=', this.svgHeight);
        },
        beforeMount() {

        },
        mounted() {
        },
        methods: {
            getPrizeType(){
                let s='chips';
                s=this.prizeType==='gems'?this.prizeType:s;
                return s;
            },
            clickLink(){
                try{
                    if (this.rewardUrl && this.showLinkBtnThis()){
                        if (this.screenshotName){               //if screenshot selected then upload image
                            this.clickUpload();
                        }
                        else {                  //else open link
                            const obj = JSON.parse(this.rewardUrl);
                            if (obj.open) {
                                window.open(obj.open);
                            }
                        }
                    }
                }catch (e) {}
            },
            clickReward(){
                try{
                    if (this.rewardUrl){
                        const obj=JSON.parse(this.rewardUrl);
                        if (obj.open){
                            window.open(obj.open);
                        }
                    }
                }catch (e) {}
            },
            async clickUpload(){
                if (this.startUpload) return;
                const state=this.$store.state;
                this.startUpload=true;
                try{
                    const uploadForm=document.getElementById(this.uploadId)      //'uploadForm'
                    const formData = new FormData(uploadForm);
                    const rewardInfo = {reward_id:parseInt(this.rewardId), loginData:state.loginData}
                    const rewardInfoS=JSON.stringify(rewardInfo);
                    formData.append('rewardInfo', rewardInfoS);
                    //for (let pair of formData.entries()) {
                    //    console.log(`${pair[0]}: ${pair[1]}`);
                    //}

                    let objRet;
                    if (true){      //fileName      (if no file name, then refresh images list)
                        objRet=await Backend.uploadImageSocial(formData);
                        MyLog(objRet);

                        if(objRet.success===true){
                            const input=document.getElementById(this.fileId);
                            if (input) input.value='';
                            await this.$store.dispatch('rewardsUpdate');
                        }
                        else{
                            state.showMessagePopup(objRet.error, state.messages.m029_5,true);
                        }
                    }
                }
                catch (e) {
                    const msg='RewardElem clickUpload catch: '+e.message+'\nstack: '+e.stack;
                    state.showMessagePopup(msg, state.messages.m029_5,true);
                }
                const input=document.getElementById(this.fileId);
                if (input) input.value='';
                this.screenshotName='';
                this.startUpload=false;
            },
            showLinkBtnThis(){        //if upload image required and image_name empty
                let b=false;
                try{
                    const startPoint=this.startPointS?JSON.parse(this.startPointS):null;
                    const image_name=startPoint?startPoint.image_name:'';

                    if (this.rewardUrl){
                        const obj=JSON.parse(this.rewardUrl);
                        if (obj.upload_req===true && !image_name && this.rewardStatus===2){
                            b=true;
                        }
                    }
                }catch (e) {}
                return b;
            },
            updateFileName(){
                const input=document.getElementById(this.fileId);      //'fileName'
                const state=this.$store.state;
                if (input.files.length > 0) {
                    const file=input.files[0];
                    const fileSize=file.size;
                    if (fileSize<Utils.uploadSize) {
                        this.screenshotName = input.files.length > 0 ? file.name : '';
                    }
                    else{
                        const msg=state.messages.m055_08;
                        this.screenshotName='';
                        state.showMessagePopup(msg, state.messages.m029_5,true);
                    }
                }
            },
        },

        props: {
            img: {
                type: String,
                default: ""
            },
            msg: {
                type: String,
                default: ""
            },
            prizeType: {
                type: String,
                default: 'chips'
            },
            prizeVal: {
                type: String,
                default: ''
            },
            prizeImage: {
                type: String,
                default: ''
            },
            rewardStatus: {
                type: Number,       // 1 - done/ 2 - progress/ 3 - not avail
                default: 3
            },
            rewardId: {
                type: String,
                default: ''
            },
            rewardUrl: {            //{open:'link'}
                type: String,
                default: ''
            },
            startPointS: {            //{"success":true,"error":"","image_name":"1739285347461_goldofgods_thumb.webp"}
                type: String,
                default: ''
            },
            uploadId: {
                type: String,
                default: 'uploadForm'
            },
            fileId: {
                type: String,
                default: 'fileName'
            },
        },

    });
</script>

<style lang="css" scoped>
    .c-reward_elem_text{
        font-family: Rubik;
        font-style: normal;
        font-weight: 400;
        overflow: hidden;
        color: #FFFFFF;
        text-align: -webkit-center;
        white-space: nowrap;
    }

</style>
